<template>
  <div>
    <h1 class="display-5 mb-4">
      How to connect Surfe to Salesforce
    </h1>

    <p class="text-muted mb-2">
      June 12, 2021
    </p>

    <p>
      Welcome to this onboarding guide.<br>
      On this page, you'll find information to help you connect Surfe to your Salesforce CRM.
    </p>

    <h1 class="mt-3">
      Check your Salesforce plan
    </h1>

    <p>
      Surfe requires API access to your Salesforce instance. Please check out the article below to determine if your current Salesforce plan has the API access enabled.
    </p>

    <p><a
      href="https://help.salesforce.com/articleView?id=000326486&type=1&mode=1"
      target="_blank"
    >
      https://help.salesforce.com/articleView?id=000326486</a></p>

    <b-alert
      show
      variant="primary"
    >
      <div class="alert-body">
        <feather-icon
          class="mr-1"
          icon="InfoIcon"
        />
        <span class="ml-1">For Salesforce Professional Edition organizations, API access can be purchased by contacting your Account Executive.</span>
      </div>
    </b-alert>

    <h1 class="mt-3">
      Grant Surfe access to your Salesforce account
    </h1>

    <p>
      Once the necessary permissions are live, you can follow the onboarding steps from the Surfe welcome panel, and grant Surfe access to Salesforce.<br>
      You're all set!
    </p>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
  },
}
</script>

<style scoped>
p {
  padding: 10px 0;
  font-size: 16px;
}

.display-5 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}
</style>
